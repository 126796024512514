import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import MusicPage from './pages/MusicPage';
import AlbumPage from './pages/AlbumPage';

function App() {

  return (
    <Router>
      <header className="max-w-screen-xl mx-auto text-center py-3">
        <a href="/" className="text-xl font-bold font-opensans">M J PAYNE</a>
        {/* <span className="text-sm block font-bold font-roboto">front end developer</span> */}
      </header>
      <nav className="bg-white border-gray-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-center mx-auto p-4">
          <div className=" w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
  
              <li>
                <a href="/music" className="">Music</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/music" element={<MusicPage />} />
          <Route path="/album/:slug" element={<AlbumPage />} />
        </Routes>
       </main>
    </Router>
  );
}

export default App;
