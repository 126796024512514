import React, { useState, useRef } from 'react';

  const AudioPlayer = ({ audioSrc }) => {
    const audioRef = useRef(null);
    const [isLooped, setIsLooped] = useState(false);

  const toggleLoop = () => {
      setIsLooped(!isLooped);
  }
    return (
    <div>
      <audio controls loop={isLooped} ref={audioRef} src={audioSrc}  />
      <button onClick={toggleLoop}>{ isLooped && "Looped" }{ !isLooped && "Not Looped" }</button>
    </div>
  );
};

export default AudioPlayer;
