import React from 'react';

function HomePage() {
  

  return (
    <div></div>
  );
}

export default HomePage;
