import React, { useEffect, useState } from 'react';
import { fetchAlbumBySlug } from '../services/api';
import { useParams } from 'react-router-dom';
import MJPAudioPlayer from '../components/MJPAudioPlayer';
import siteicons from '../assets/siteicons/siteicons';

function MusicPage() {
    const { slug } = useParams();
    const [album, setAlbum] = useState([]);
    const [selectedTrack, setSelectedTrack] = useState(null); // Store selected track
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchAlbumBySlug(slug);
                const album = data.items[0];
                setAlbum(album);
                setSelectedTrack(album.fields.track[0].fields.file.url);
                setLoading(false);
            } catch (error) {
                setAlbum([{ error: "No data" }]);
            }
        };
        fetchData();
    }, [slug]); // Get this once on page load

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedTrack(selectedValue);
    };

    //When clicked select next track and stop at last, update the select also
    const handleNext = () => {
        const currentIndex = album.fields.track.findIndex(
            (track) => track.fields.file.url === selectedTrack
        );
        if (currentIndex + 1 < album.fields.track.length) {
            setSelectedTrack(album.fields.track[currentIndex + 1].fields.file.url);
        }
    }
    //When clicked select previous track and stop at last, update the select also
    const handlePrevious = () => {
        const currentIndex = album.fields.track.findIndex(
            (track) => track.fields.file.url === selectedTrack
        );
        if (currentIndex - 1 >= 0) {
            setSelectedTrack(album.fields.track[currentIndex - 1].fields.file.url);
        }
    };

    return (
        <section>
            <h1>Music {slug}</h1>
            <div className="bg-white max-w-screen-sm mx-auto">
                {!loading && (
                    <div className="mb-6">
                        <h1 className="text-2xl font-bold">{album.fields.albumName}</h1><div className="flex justify-between">
                            <button
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onClick={handlePrevious}
                            >
                                Previous
                            </button>
                            <button
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onClick={handleNext}
                            >
                                Next
                            </button>
                        </div>
                        <div>
                            <select
                                id="track_select"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={handleSelectChange} // Attach onChange to the select element
                                value={selectedTrack} // Set the selected value
                            >
                                <option value="" disabled>Select a track</option>
                                {album.fields.track.map((track) => (
                                    <option
                                        value={track.fields.file.url}
                                        key={track.fields.title}
                                    >
                                        {track.fields.title}
                                    </option>
                                ))}
                            </select>
                            <MJPAudioPlayer audioSrc={selectedTrack} />

                        </div>
                    </div>
                )}
                <div className="grid grid-rows-1 grid-flow-col gap-6">
                    <h2 className="text-md font-bold pb-3">THIS SITE USES:</h2>
                    {Object.entries(siteicons).map(([iconName, iconPath]) => (
                        <img key={iconName} src={iconPath} alt={iconName} className="" />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default MusicPage;