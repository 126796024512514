import React from 'react';

function MusicPage() {

    return (
        <section>
        <h1>Music</h1>
        </section>
    );
}

export default MusicPage;