import { createClient } from 'contentful';

// Create a single instance of the Contentful client
const contentfulClient = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});

export async function fetchEntry(entryId) {
  try {
    const entry = await contentfulClient.getEntry(entryId);
    return entry;
  } catch (error) {
    return null; 
  }
}

export async function fetchAlbumBySlug(slug) {
  console.log(slug)
  try {
    const entry = await contentfulClient.getEntries({
      'fields.slug': slug,
      content_type: 'album'
    });
    console.log(entry)
    return entry;
  } catch (error) {
    return null; 
  }
}
export async function fetchWorkContentExamples() {
  try {
    const contentType = await contentfulClient.getEntries({
      content_type: 'workExample'
    });
    return contentType;
  } catch (error) {
    console.error(error);
    return null; 
  }
}

export async function fetchAlbums() {
  try {
    const contentType = await contentfulClient.getEntries({
      content_type: 'album'
    });
    return contentType;
  } catch (error) {
    console.error(error);
    return null; 
  }
}